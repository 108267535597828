<template lang="html">
  <!-- <section class="why-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <h2 class="sec-title">Why 1ST Choice?</h2>
          <p>
            1ST Choice prides itself on delivering high quality, easy to understand educational materials to our students
          </p>

          <p class="mt-3">
            <router-link to="/courses">Check out our courses and try us today</router-link>
          </p>

          <hr class="line" />
        </div>
      </div>
    </div>
  </section> -->

  <section class="easy-section">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-lg-9">
          <h2 class="sec-title">EASY AS 1,2,3</h2>
          <p class="mt-2">
            1ˢᵗ Choice CE prides itself on delivering hight-quality, easy to understand educational materials to our students.
          </p>
          <p class="mt-2 mt-sm-0 f-w-600">
            Check out our courses and try us out today!
          </p>

          <hr class="line" />
        </div>

        <div class="col-12 box-steps">
          <div class="row">
            <div class="col-lg-4 col-step">
              <div class="box-icon">
                <div class="circle">
                  <i class="far fa-search"></i>
                </div>
              </div>

              <h4 class="title">1. SELECT YOUR COURSE</h4>
              <h6>Select the course you need.</h6>
            </div>

            <div class="col-lg-4 col-step">
              <div class="box-icon">
                <div class="circle">
                  <i class="fal fa-books"></i>
                </div>
              </div>

              <h4 class="title">2. COMPLETE IT!</h4>
              <h6>Complete your course "At your own pace"</h6>
            </div>

            <div class="col-lg-4 col-step">
              <div class="box-icon">
                <div class="circle">
                  <i class="fal fa-file-certificate i-certificate"></i>
                </div>
              </div>

              <h4 class="title">3. RENEW YOUR LICENCE!</h4>
              <h6>We will submit your credits upon completion.</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
}
</script>
