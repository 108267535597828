import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

//Componentes
//import Login from './components/admin/Login.vue';

const page="./components/page/";

const MyRouter = new VueRouter({
  	routes:[
      { path: '*', name:'404', component: require(page+'404.vue').default, meta:{title:"Not found"} },
      { path: '/', name:'Home', component: require(page+'home.vue').default },
      { path: '/about', name:'About', component: require(page+'about-us/index.vue').default, meta:{title:"About"}},
      { path: '/discounts',name:'Corporate discounts', component: require(page+'corporate-discounts/index.vue').default, meta:{title:"Corporate discounts"}},
      { path: '/faq',name:'FAQ', component: require(page+'faq/index.vue').default, meta:{title:"FAQ"}},
      { path: '/contact',name:'Contact', component: require(page+'contact/index.vue').default, name: 'contacto', meta:{title:"Contact"}},

      { path: '/terms',name:'Terms and conditions', component: require(page+'text-page/terms.vue').default, meta:{title:"Terms and conditions"}},
	    { path: '/privacy',name:'Privacy, confidentiality, ans security policy', component: require(page+'text-page/privacy.vue').default, meta:{title:"Privacy, confidentiality, ans security policy"}},
      { path: '/courses',name:'Courses', component: require(page+'courses/all.vue').default, meta:{title:"Courses"}},
      { path: '/courses/search',name:'Courses', component: require(page+'courses/search.vue').default, meta:{title:"Courses"}},
      { path: '/courses/index/:id/:state?',name:'Courses', component: require(page+'courses/index.vue').default, meta:{title:"Courses"}},
      { path: '/courses/checkout/:state/:id',name:'Course', component: require(page+'courses/detail.vue').default, meta:{title:"Course"}},
	    { path: '/state/:category_id/:id',name:'Courses', component: require(page+'courses/state.vue').default, meta:{title:"Courses"}},

      { path: '/enroll',name:'Enroll', component: require(page+'enroll/index.vue').default, meta:{title:"Enroll"}},
	    { path: '/thankyou/:id',name:'Thank You', component: require(page+'enroll/finish.vue').default, meta:{title:"Thank You"}},

      { path: '/cart/:course_id/:order_id',name:'Cart', component: require(page+'cart/index.vue').default, meta:{title:"Cart"}},

      { path: '/access',name:'Login', component: require(page+'user-access/login.vue').default, meta:{title:"Login"}},
      { path: '/access/reset-password',name:'Reset password', component: require(page+'user-access/reset-password.vue').default, meta:{title:"Reset password"}},
      { path: '/access/sign-up',name:'Sign up', component: require(page+'user-access/sign-up.vue').default, meta:{title:"Sign up"}},

  	  { path: '/blogs',name:'Blogs', component: require(page+'blog/index.vue').default, meta:{title:"Blog"}},
  	  { path: '/blogs/:id',name:'Blog', component: require(page+'blog/detail.vue').default, meta:{title:"Blog"}},

      { path: '/account',name:'Account', component: require(page+'usuario-cuenta/index.vue').default, meta:{ title: 'Mi datos' },
  			children: [
    			{
    				path: '/',
					name:'My information',
    				component: require(page+'usuario-cuenta/my-information.vue').default,
    				meta:{ title: 'My information' }
    			},
          {
    				path: 'my-photo',
					name:'My photo',
    				component: require(page+'usuario-cuenta/my-photo.vue').default,
    				meta:{ title: 'My photo' }
    			},
    			{
    				path: 'password',
					name:'Change password',
    				component: require(page+'usuario-cuenta/password.vue').default,
    				meta:{ title: 'Change password' }
    			},
          {
    				path: 'my-courses',
					name:'My courses',
    				component: require(page+'usuario-cuenta/my-courses.vue').default,
    				meta:{ title: 'My courses' }
    			},
          {
    				path: 'my-certificates',
					name:'My certificates',
    				component: require(page+'usuario-cuenta/my-certificates.vue').default,
    				meta:{ title: 'My certificates' }
    			},
				{
    				path: 'share-course',
					name:'Share courses',
    				component: require(page+'usuario-cuenta/share-course.vue').default,
    				meta:{ title: 'Share courses' }
    			},

  	  	]
  		},

      // { path: '/my-course/:id', component: require(page+'my-course/index.vue').default, meta:{title:"My course"}},

      { path: '/my-course',name:'My course', component: require(page+'my-course/index.vue').default, meta:{ title: 'My course' },
  			children: [
    			{
    				path: ':user_has_courses_id',
					name:'My course',
    				component: require(page+'my-course/empty.vue').default,
    				meta:{ title: 'My course' }
    			},
          {
    				path: ':user_has_courses_id/module/:module_id',
            name: 'modulePage',
    				component: require(page+'my-course/module.vue').default,
    				meta:{ title: 'My course' }
    			},
          {
    				path: ':user_has_courses_id/exam/:module_id',
            name: 'examPage',
    				component: require(page+'my-course/exam.vue').default,
    				meta:{ title: 'My course' }
    			},
          {
    				path: ':user_has_courses_id/exam-finished/:module_id',
            name: 'examFinishedPage',
    				component: require(page+'my-course/exam-finished.vue').default,
    				meta:{ title: 'My course' }
    			},
        ]
      },

	    // { path: '/checkout', component: require(page+'checkout.vue').default, meta:{title:"Checkout"}},
	],
	mode: 'history',
});

MyRouter.beforeEach((to, from, next) => {
	window.scrollTo(0,0);
	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		window.app.__vue__.$refs.loadingBar.start();
	}
	next();
});

MyRouter.afterEach((to, from) => {

	if(window.app.__vue__ && window.app.__vue__.$refs.loadingBar){
		setTimeout(()=>{
			window.app.__vue__.$refs.loadingBar.done();
		},50);
	}


});

//Titulos del website
import VueDocumentTitlePlugin from "vue-document-title-plugin";
Vue.use(VueDocumentTitlePlugin, MyRouter,
	{ defTitle: "1st Choice CE", filter: (title)=>{ return title+" - 1st Choice CE"; } }
);

// export {routes};
export default MyRouter;
