<template lang="html">
  <div id="courses-results-page">

    <section class="container oversized-container results-section">
      <div class="row">
        <div class="col-12 col-title">
          <div class="box-simple-title">
            <h6>Courses</h6>
          </div>

          <div class="box-state">
            <div class="box">
              <div class="col col-label">
                <span>State:</span>
              </div>

              <div class="col col-select">
                <b-form-select v-model="courseState">
                  <b-form-select-option :value="null" disabled>Select an option</b-form-select-option>

                  <b-form-select-option :value="s.id" v-for="(s,indx) in states" :key="indx">{{s.name}}</b-form-select-option>

                </b-form-select>
              </div>
            </div>
          </div>

          <div class="box-title" >
            <h1>Check out our Continuing Education Courses</h1>
            <h2>Select Your State To Begin</h2>
          </div>
        </div>
      </div>

      <div class="row row-items-xl-4" >
        <div class="col-md-6 col-lg-4 mb-4 box-course-sample-1" v-for="(c, cInx) in courses" :key="'cInx-'+cInx">
          <router-link class="box-course" :to="'/courses/checkout/'+c.state_custom+'/'+c.custom_url">
            <!-- <div class="box-image" v-bind:style="{ backgroundImage: 'url('+c.image_url+')' }" style="background-size: contain !important;">
            </div> -->

            <div class="box-description">
              <h6 class="c-req">{{c.state}}</h6>
              <h6 class="c-name">{{c.name}}</h6>
              <div class="c-desc" v-html="c.description">
              </div>

             <!--  <h6 class="c-req">No test or quiz required</h6>

              <h6 class="c-cer">Certificate emailed immediately upon completion.</h6> -->

              <div class="row box-button">
                <div class="col-12 col-more">
                  <h6 class="c-more">See more information</h6>
                </div>

                <div class="col-12 px-0">
                  <span class="box-price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(c.price) }} USD</span>
                </div>

                <div class="col-12 text-center">
                  <a class="btn-enroll" @click="$root.enroll(c, $event)">Enroll</a>

                  <h6 class="c-inf">Ask for information 1-800-698-2770 </h6>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </section>

    <WhyComponent></WhyComponent>

  </div>
</template>

<script>
export default {
  data(){
    return{

      courses: [],
      allcourses: [],
      courseState: null,
    }

  },
  watch:{

    'courseState':function(){
      var newdata = [];
      if (this.courseState != null && this.courseState != 0) {
        for (let x = 0; x < this.allcourses.length; x++) {
            if (this.allcourses[x]['state_id']  == this.courseState) {
              newdata.push(this.allcourses[x]);
            }
        }
      }
      else{
          newdata = this.allcourses;
      }
      this.courses = newdata;

    },
  },
  methods: {

    getCourses(){
      axios.get(tools.url("/api/courses")).then((response)=>{
          this.courses = response.data;
          this.allcourses = response.data;

        }).catch((error)=>{
        });
    },
    getStates(){
      axios.get(tools.url("/api/states")).then((response)=>{
          this.states = response.data;
        }).catch((error)=>{
        });
    },

  },

  mounted(){

    this.getCourses();
    this.getStates();
  },
}
</script>
