<template lang="html">
  <div id="corporate-discounts-page">

    <!-- <section class="placed-backg banner-section" v-bind:style="{ backgroundImage: 'url(public/images/pages/corporate-discounts/banner.jpg)' }">
      <div class="inside" style="width: 28%;">
        <h1 style="background-color: white; border: 1px solid white; border-radius: 10px;">Corporate discounts</h1>
      </div>
    </section> -->

    <section class="info-section">
      <div class="container oversized-container">
        <div class="row align-items-center">
          <div class="col-lg-6 col-xl-5 col-text">
            <h1 class="title-">Corporate discounts</h1>
            <h2>We offer big savings for corporate clients</h2>
            <h3>Take a look at our discounts for corporations</h3>
            <p class="f-w-600">
              ORDER 3 OR MORE COURSES AT A TIME AND RECEIVE A VOLUME DISCOUNT WITH THE FOLLOWING RATES:
            </p>

            <table class="mt-4 table">
              <thead>
                <tr>
                  <th>Quantity Ordered</th>
                  <th>Discount</th>
                </tr>
              </thead>

              <tbody>
                <tr>
                  <th>3 Or More</th>
                  <td>20%</td>
                </tr>
                <tr>
                  <th>5 Or More</th>
                  <td>30%</td>
                </tr>
              </tbody>
            </table>

            <p class="mt-3">
               Upon ordering, select your desired quantity and the discount will automatically be applied. If each of the enrollees would like to pay for the couse separately, contact us so we can set this up for you today.
            </p>
          </div>

          <div class="col-lg-6 col-xl-6 col-image">
            <img src="/public/images/pages/corporate-discounts/image.jpg">
          </div>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
export default {
}
</script>
