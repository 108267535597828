<template lang="html">
    <div id="courses-results-page">

      <section class="container oversized-container results-section">
        <div class="row">
          <div class="col-12 col-title">
            <div class="box-simple-title">
              <h6>Courses</h6>
            </div>

            <div class="box-title">
              <h2>{{state.name}}</h2>
            </div>
          </div>
        </div>

        <div class="d-block" v-if="courses.length == 0">
          <h2 class="my-5 text-center"><i class="fas fa-empty-set h1"></i><br />No courses found</h2>
        </div>

        <div class="row row-items-xl-4" v-else>
          <div class="col-md-6 col-lg-4 mb-4 box-course-sample-1" v-for="(c, cInx) in courses" :key="'cInx-'+cInx">
            <router-link class="box-course" :to="'/courses/checkout/'+c.state+'/'+c.custom_url">
              <!-- <div class="box-image" v-bind:style="{ backgroundImage: 'url('+c.image_url+')' }" style="background-size: contain !important;">
              </div> -->

              <div class="box-description">
                <h6 class="c-req">{{c.state}}</h6>
                <h6 class="c-name">{{c.name}}</h6>
                <div class="c-desc" v-html="c.description">
                </div>

               <!--  <h6 class="c-req">No test or quiz required</h6>

                <h6 class="c-cer">Certificate emailed immediately upon completion.</h6> -->

                <div class="row box-button">
                  <div class="col-lg-5 col-more">
                    <h6 class="c-more">See more information</h6>
                  </div>

                  <div class="col-lg-7">
                    <span class="box-price">$ {{ Intl.NumberFormat("en-US", { minimumFractionDigits: 2 }).format(c.price) }} USD</span>
                  </div>

                  <div class="col-12 text-center">
                    <a class="btn-enroll" @click="$root.enroll(c, $event)">Enroll</a>

                    <h6 class="c-inf">Ask for information 1-800-698-2770 </h6>
                  </div>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </section>

      <WhyComponent></WhyComponent>

    </div>
  </template>

  <script>
  export default {
    data(){
      return{
        courses: [],
        allcourses: [],

        state:{
          name:null
        }
      }

    },

    watch:{
      '$route.params.id':function(){
        this.getCourses();
      },
      '$route.params.category_id':function(){
        this.getCourses();
      },
    },

    methods: {
      getCourses(){
        axios.get(tools.url("/api/coursesstates/"+this.$route.params.category_id+"/"+this.$route.params.id)).then((response)=>{
            this.courses = response.data.courses;
            this.allcourses = response.data.courses;
            this.state = response.data.state;
          }).catch((error)=>{
          });
      },
    },

    mounted(){
      this.getCourses();
    },
  }
  </script>
