 <template lang="html">
  <header id="header">
    <div class="header-content">
      <div class="wr-h">
        <b-navbar toggleable="lg" type="light" variant="light">
          <div class="container px-0 oversized-container">
            <b-navbar-brand to="/">
              <img src="/public/images/shared/logo.png">
            </b-navbar-brand>

            <div class="btn-extras">
              <router-link to="/access" v-if="$root.logged == false"><i class="fas fa-user"></i> LOG IN</router-link>
            </div>

            <b-navbar-toggle target="nav-collapse"><i class="far fa-bars icon"></i></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav>
                <b-nav-item class="simple-item d-lg-none" to="/">Home</b-nav-item>
                <!-- <b-nav-item class="simple-item" to="/courses">Courses</b-nav-item> -->
                <b-nav-item-dropdown class="simple-item courses-dropdown hover-dropdown-mobile" right>
                  <template #button-content>
                    <span>Our courses</span>
                  </template>

                  <b-dropdown-item :to="'/courses/index/'+cate.custom_url" v-for="(cate,indx) in categories" :key="indx">{{cate.name}}</b-dropdown-item>
                </b-nav-item-dropdown>

                <li class="nav-item b-nav-dropdown dropdown simple-item courses-dropdown hover-dropdown hover-dropdown-desktop">
                  <router-link class="nav-link dropdown-toggle" to="/courses">
                    <span>Our courses</span>
                  </router-link>

                  <span class="chevron-icon">
                    <ul class="dropdown-menu">
                      <li role="presentation" v-for="(cate,indx) in categories" :key="indx">
                        <router-link class="dropdown-item" role="menuitem" :to="'/courses/index/'+cate.custom_url">{{cate.name}}</router-link>
                      </li>
                    </ul>
                  </span>
                </li>

                <!-- <b-nav-item class="simple-item" to="/courses">All Courses</b-nav-item> -->
                <b-nav-item class="simple-item" to="/about">About us</b-nav-item>
                <b-nav-item class="simple-item" to="/discounts">Corporate discounts</b-nav-item>
                <b-nav-item class="simple-item" to="/faq">FAQ</b-nav-item>
                <b-nav-item class="simple-item" to="/contact">Contact us</b-nav-item>

                <b-nav-item class="simple-item focus" to="/access" v-if="$root.logged == false"><i class="fas fa-user"></i> Log in</b-nav-item>
                <b-nav-item class="simple-item focus" to="/account" v-if="$root.logged == true"><i class="fas fa-user"></i> My account</b-nav-item>
                <b-nav-item class="simple-item focus" @click="logout" v-if="$root.logged == true"><i class="fas fa-sign-out"></i> Log out</b-nav-item>
                <li class="nav-item simple-item d-lg-none">
                  <a class="nav-link"><hr class="my-0" /></a>
                </li>
                <!-- <li class="nav-item simple-item d-lg-none">
                  <router-link class="nav-link" to="/enroll"><i class="fal fa-shopping-cart icon"></i> Cart</router-link>
                </li> -->
                <li class="nav-item simple-item d-lg-none">
                  <a class="nav-link" @click="$refs['modal-search'].show();"><i class="fal fa-search icon"></i> Search</a>
                </li>
                <b-nav-item-dropdown class="simple-item d-lg-none" right>
                  <template #button-content>
                    <span><i class="fal fa-user"></i> My account</span>
                  </template>
                  <b-dropdown-item to="/access" v-if="$root.logged == false">Log in</b-dropdown-item>
                  <b-dropdown-item to="/access/sign-up" v-if="$root.logged == false">Sign up</b-dropdown-item>

                 <b-dropdown-item to="/account" v-if="$root.logged == true">My information</b-dropdown-item>
                  <b-dropdown-item to="/account/password" v-if="$root.logged == true">Change password</b-dropdown-item>
                  <b-dropdown-item to="/account/my-courses" v-if="$root.logged == true">My courses</b-dropdown-item>
                  <b-dropdown-item to="/account/my-certificates" v-if="$root.logged == true">My certificates</b-dropdown-item>
                  <li role="presentation" v-if="$root.logged == true">
                    <a class="dropdown-item" role="menuitem" @click="logout">Log out</a>
                  </li>
                </b-nav-item-dropdown>
              </b-navbar-nav>

              <b-navbar-nav class="ml-auto">
                <li class="nav-item simple-item question-item">
                  <div class="nav-link">
                    Questions? Call us at <a href="tel:1800507-9963">1-800-698-2770</a><br />Hablamos español
                  </div>
                </li>

                <!-- <li class="nav-item d-none d-lg-inline-flex icon-item">
                  <router-link class="nav-link" to="/enroll"><i class="fal fa-shopping-cart icon"></i></router-link>
                </li> -->
                <li class="nav-item d-none d-lg-inline-flex icon-item">
                  <a class="nav-link" @click="$refs['modal-search'].show();"><i class="fal fa-search icon"></i></a>
                </li>
                <!-- <li class="nav-item d-none d-lg-inline-flex icon-item">
                  <router-link class="nav-link" to="/contact"><i class="fal fa-envelope icon"></i></router-link>
                </li> -->
                <!-- <b-nav-item-dropdown class="d-none d-lg-inline-flex icon-item red-icon" right>
                  <template #button-content>
                    <span><i class="fal fa-user icon"></i></span>
                  </template>
                  <b-dropdown-item to="/access" v-if="$root.logged == false" >Log in</b-dropdown-item>
                  <b-dropdown-item to="/access/sign-up" v-if="$root.logged == false">Sign up</b-dropdown-item>

                  <b-dropdown-item to="/account" v-if="$root.logged == true">My information</b-dropdown-item>
                  <b-dropdown-item to="/account/password" v-if="$root.logged == true">Change password</b-dropdown-item>
                  <b-dropdown-item to="/account/my-courses" v-if="$root.logged == true">My courses</b-dropdown-item>
                  <b-dropdown-item to="/account/my-certificates" v-if="$root.logged == true">My certificates</b-dropdown-item>
                  <li role="presentation" v-if="$root.logged == true">
                    <a class="dropdown-item" role="menuitem" @click="logout">Log out</a>
                  </li>
                </b-nav-item-dropdown> -->
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>

        <b-navbar toggleable="lg" type="dark" variant="info" class="extra-navbar-menu" style="height: 66px;">
          <div class="container oversized-container">
            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav class="mx-auto">
                <b-nav-item-dropdown right v-bind:class="{ 'no-subcategories' : c.courses.length == 0 }" v-for="(c,chInx) in categories" :key="'chInx-'+chInx">
                  <template #button-content>
                    <router-link :to="'/courses/index/'+c.custom_url">{{c.name}}</router-link>
                    <!-- <span>{{c.name}}</span> -->
                  </template>
                  <b-dropdown-item :to="'/courses/index/'+c.custom_url+'/'+course.custom_url" v-for="(course,coursehInx) in c.courses"  :key="'course-'+coursehInx">{{course.name}}</b-dropdown-item>
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </div>
        </b-navbar>


        <!-- <b-navbar toggleable="lg">
          <div class="container px-0 oversized-container">
            <router-link style="text-decoration: none;color:white" v-for="(c,indx) in categories" :key="indx" :to="'/courses/'+c.id">{{c.name}}</router-link>
          </div>
        </b-navbar> -->
      </div>
    </div>

    <b-modal ref="modal-search" modal-class="modal-search-e" size="lg" title="" @hidden="form.keywords = null" hide-footer no-close-on-esc no-close-on-backdrop>
      <!-- NOTA: form.keywords será null siempre al cerrar el modal, esto por usar el evento @hidden -->

      <b-form @submit="onSubmitSearch">
        <b-form-group>
          <b-form-input
            v-model="form.keywords"
            type="text"
            placeholder="Search"
            size="lg"
            required
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" class="btn-search"><i class="far fa-search"></i></b-button>
      </b-form>
    </b-modal>

  </header>
</template>

<script>
export default {
  data(){
    return{
      form: {
        keywords: null,
      },
      categories:[],
      courses:[]
    }
  },

  methods: {
    onSubmitSearch(event) {
      event.preventDefault();
      this.$router.push({path: '/courses/search', query: {keywords:this.form.keywords}});
      this.$refs['modal-search'].hide();
    },
    getCategories(){
      axios.get(tools.url("/api/categoriesheader")).then((response)=>{
          this.categories = response.data;
        }).catch((error)=>{
        });
    },
    logout(){
        axios.post(tools.url("/logout")).then((response)=>{
            this.$parent.user = {};
            this.$parent.logged = false;
            this.$router.push('/access');
        }).catch(()=>{});
    },
    getCourses(){
      axios.get(tools.url("/api/courses")).then((response)=>{
          this.courses = response.data;
        }).catch((error)=>{
        });
    },
  },

  mounted(){
    this.getCategories();
    this.getCourses();
  },
}
</script>
