<template lang="html">
  <div id="user-access-page" class="placed-backg">

    <div class="container">
      <div class="row mx-0 flex-center-xy page-size">

        <div class="form-container">
          <div class="box-color"><i><u class="fas fa-user"></u></i></div>

          <b-form class="form" @submit.prevent="register">

            <h1 class="mb-3">Sign up</h1>

            <b-form-group>
              <b-form-input type="text" v-model="form.name" required placeholder="Name"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input type="text" v-model="form.lastname" required placeholder="Last name"></b-form-input>
            </b-form-group>

            <b-form-group>
              <b-form-input type="email" v-model="form.email" required placeholder="Email" readonly  onfocus="this.removeAttribute('readonly');"></b-form-input>
            </b-form-group>

            <b-form-group class="cus-f-group-2 c-f-g-pass">
              <b-form-input id="i-1" type="password" v-model="form.password" required placeholder="Password"></b-form-input>
              <i class="fas ic-eye" v-bind:class="{ 'fa-eye' : showpass, 'fa-eye-slash' : !showpass }"  @click="showpass = !showpass"></i>
            </b-form-group>

            <b-form-group class="cus-f-group-2 c-f-g-pass">
              <b-form-input id="i-2" type="password" v-model="form.password_confirmation" required placeholder="Confirm password"></b-form-input>
              <i class="fas ic-eye" v-bind:class="{ 'fa-eye' : showpasstwo, 'fa-eye-slash' : !showpasstwo }"  @click="showpasstwo = !showpasstwo"></i>
            </b-form-group>

            <div class="d-inline-block mb-3">
              <b-form-checkbox v-model="form.tos" name="usertype" value="1" unchecked-value="" required>
                I accept the <router-link target="_blank" to="/terms">terms of use</router-link>
              </b-form-checkbox>
            </div>

            <p class="mb-1">
              <router-link to="/access">Click here to login if you already have an account?</router-link>
            </p>

            <div class="mb-4 form-errors" v-if="formErrors.length">
              <hr />
              <ul>
                <li class="text-danger" v-for="formError in formErrors">
                  <small>{{ formError }}</small>
                </li>
              </ul>
            </div>

            <b-form-group class="mt-3">
              <b-button type="submit" class="btn-blue" variant="primary">Sign up</b-button>
            </b-form-group>

          </b-form>
        </div>

      </div>
    </div>

    <sweet-modal :icon="modal.icon" :blocking="modal.block" :hide-close-button="modal.block" ref="modal">
        <div class="fa-3x" v-if="modal.icon== ''"><i class="fas fa-spinner fa-pulse"></i></div><br/>
        {{modal.msg}}
        <div class="col-12 text-center" style="padding-top: 20px;" v-if="modal.icon == 'success'">
        <b-button class="btn btn-primary" slot="button" v-on:click.prevent="$refs.modal.close();">OK</b-button>
        </div>
    </sweet-modal>

  </div>
</template>

<script>
export default {
  data(){
    return{
      formErrors: [],

      showpass:false,
      showpasstwo:false,

      form: {
        name: '',
        lastname: '',
        email: '',
        password: '',
        password_confirmation: ''
      },

      modal:{
        msg:'',
        icon:'',
        block:false,
      },
    }
  },

  methods: {
    register(evt){
      this.modal.block = true;
      this.modal.icon = '';
      this.modal.msg = 'Loading';
      this.$refs.modal.open();

     this.formErrors = [];
     if(this.form.password.length < 6){
         this.formErrors.push("The password must have at least 6 characters");
         this.modal.block = false;
         this.modal.icon = 'error';
         this.modal.msg = 'The password must have at least 6 characters';

     } else {
       if(this.form.password !== this.form.password_confirmation){
           this.formErrors.push("The password and password confirmation fields must match");
           this.modal.block = false;
           this.modal.icon = 'error';
           this.modal.msg = 'The password and password confirmation fields must match';

       } else {
            axios.post('/api/user/register',this.form).then((response)=>{
              this.modal.block = false;
              this.$refs.modal.close();
               this.$parent.user = response.data;
               this.$parent.logged = true;
                  axios.get('/sanctum/csrf-cookie').then(() => {
                   this.$router.push("/account");
               });
                }).catch((error)=>{
               this.handleErrors(error);
           });
       }
     }
    },

    handleErrors:function(errors){
        var err="";

        if (errors.response) {
            if(errors.response.data.errors){
                jQuery.each(errors.response.data.errors,(k,v)=>{
                    err+="*"+v[0]+"\n";
                });
            }
        }
        else{
            console.log(errors);
            err="Unknown error.";
        }

        this.modal.icon = "error";
        this.modal.msg = err;
        this.modal.block = false;


    },
  },

  mounted(){
    if(this.logged){
        this.$router.push("/account");
    }
  },

  watch:{
    'showpass':function (val) {
      if (val == true) {
        document.getElementById("i-1").type="text";
      }
      else if (val == false) {
        document.getElementById("i-1").type="password";
      }
    },

    'showpasstwo':function (val) {
      if (val == true) {
        document.getElementById("i-2").type="text";
      }
      else if (val == false) {
        document.getElementById("i-2").type="password";
      }
    }
  },
}
</script>
